<template>
  <div id="data-list-thumb-view" class="data-list-container" :class="{ 'data-list-container--mobile': isMobile }">
    <chats-view-sidebar v-if="!isMobile" :isSidebarActive="showChatsSideBar" @closeSidebar="toggleChatsDataSidebar" :data="chats" />
    <LeadOverviewSidebar
      v-if="isMobile"
      :isSidebarActive="isOpenedLeadOverviewSidebar"
      :isMobile="isMobile"
      @onSaveContacted="saveContacted"
      @closeSidebar="closeLeadOverviewSidebar"
    />

    <vs-prompt
      :title="$t('modals.deleteLead')"
      @cancel="onCloseModal"
      @accept="deleteLead"
      @close="onCloseModal"
      color="danger"
      :cancel-text="$t('vue.cancel')"
      :accept-text="$t('vue.delete')"
      :active.sync="removeLead"
    >
      <div class="con-exemple-prompt" v-if="selectedLead">
        <p>
          <strong>{{ selectedLead.name }}</strong>
        </p>
        {{ $t('modals.deleteLeadQuestion') }}
      </div>
    </vs-prompt>

    <connect-modal :show="showVisitor" :width="'100%'" :height="'100%'" class="visitor-modal" bkcolor="#F7F7F7">
      <visitor />
    </connect-modal>

    <div class="leadoverview-container">
      <div :class="!HAS_ACCESS && !isMobileApp ? 'blur-background' : ''">
        <vs-table
          ref="table"
          v-model="selected"
          pagination
          :max-items="itemsPerPage"
          search
          :data="sortedLeads"
          :noDataText="$t('views.home.noDataAvailable')"
          @selected="handleSelected"
        >
          <div slot="header" class="relative flex flex-wrap-reverse items-center flex-grow justify-start data-list-subscriptions-wrapper">
            <div class="lead-overview__headers">
              <div class="lead-overview__header">
                <div class="mt-1 mb-1 mr-5">
                  <subscriptions :isMobile="isMobile"></subscriptions>
                </div>
                <div class="mt-1 mb-1 mr-5" v-if="activeUserInfo && ['superadmin', 'admin'].includes(activeUserInfo.role)" style="width: 200px">
                  <multiselect
                    class="lead-overview__header--multiselect"
                    v-model="groups"
                    :options="userGroupList"
                    :multiple="true"
                    :close-on-select="false"
                    :clear-on-select="false"
                    :preserve-search="true"
                    :selectLabel="''"
                    :selectedLabel="''"
                    :deselectLabel="''"
                    :placeholder="$t('vue.selectUserGroup')"
                    label="name"
                    track-by="name"
                    :preselect-first="false"
                  >
                    <template slot="selection" slot-scope="{ values, isOpen }">
                      <vs-chip v-for="(agent, index) of values" :key="index" v-show="!isOpen" color="primary">{{ agent.name }}</vs-chip>
                    </template>
                  </multiselect>
                </div>
                <div class="mt-1 mb-1 mr-5">
                  <vx-tooltip v-if="!isMobile" :text="$t('vue.download')" position="top">
                    <vs-button color="primary" type="border" size="medium" icon-pack="feather" icon="icon-download" @click="exportCSV"></vs-button>
                  </vx-tooltip>
                </div>
                <div class="mt-1 mb-1 mr-5">
                  <vs-dropdown v-if="!isMobile" vs-trigger-click class="cursor-pointer mr-4">
                    <div
                      class="p-4 border border-solid d-theme-border-grey-light d-theme-dark-bg cursor-pointer flex items-center justify-between font-medium"
                      style="border-radius: 5px"
                    >
                      <span class="mr-2"
                        >{{ currentPage * itemsPerPage - (itemsPerPage - 1) }} -
                        {{ sortedLeads.length - currentPage * itemsPerPage > 0 ? currentPage * itemsPerPage : sortedLeads.length }} of {{ queriedItems }}</span
                      >
                      <feather-icon icon="ChevronDownIcon" svgClasses="h-4 w-4" />
                    </div>
                    <!-- <vs-button class="btn-drop" type="line" color="primary" icon-pack="feather" icon="icon-chevron-down"></vs-button> -->
                    <vs-dropdown-menu>
                      <vs-dropdown-item @click="itemsPerPage = 5">
                        <span>5</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="itemsPerPage = 10">
                        <span>10</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="itemsPerPage = 15">
                        <span>15</span>
                      </vs-dropdown-item>
                      <vs-dropdown-item @click="itemsPerPage = 20">
                        <span>20</span>
                      </vs-dropdown-item>
                    </vs-dropdown-menu>
                  </vs-dropdown>
                </div>
              </div>
              <div class="lead-overview__header2">
                <div class="flex flex-wrap-reverse items-center"></div>
                <!-- ITEMS PER PAGE -->
              </div>
            </div>
          </div>

          <template slot="thead">
            <vs-th sort-key="contacted">
              {{ $t('vue.contacted') }}
            </vs-th>
            <vs-th sort-key="name">
              {{ $t('vue.name') }}
            </vs-th>

            <template v-if="!isMobile">
              <vs-th sort-key="email">
                {{ $t('vue.contactDetails') }}
              </vs-th>

              <vs-th sort-key="widgetName">
                {{ $t('vue.widgetName') }}
              </vs-th>

              <vs-th sort-key="created">
                {{ $t('vue.date') }}
              </vs-th>

              <vs-th>
                {{ $t('vue.status') }}
              </vs-th>
              <vs-th>
                {{ $t('vue.action') }}
              </vs-th>
            </template>
          </template>

          <template slot-scope="{ data }">
            <vs-tr :data="tr" :key="indextr" v-for="(tr, indextr) in data">
              <vs-td :data="data[indextr].contacted" class="lead-overview--column">
                <vs-switch
                  color="#275D73"
                  vs-icon-on="check"
                  vs-icon-off="block"
                  @click.prevent="saveContacted(data[indextr])"
                  v-model="data[indextr].contacted"
                >
                  <span slot="on">{{ isMobile ? $t('vue.yes') : $t('vue.yes').toUpperCase() }}</span>
                  <span slot="off">{{ isMobile ? $t('vue.no') : $t('vue.no').toUpperCase() }}</span>
                </vs-switch>
              </vs-td>
              <vs-td :data="data[indextr].name" class="lead-overview--column lead-overview--wrap">
                {{ data[indextr].name }}
              </vs-td>
              <template v-if="!isMobile">
                <vs-td :data="data[indextr].email" class="lead-overview--column lead-overview--wrap">
                  <div>
                    <a :href="`mailto:${data[indextr].email}`">{{ data[indextr].email }} </a>

                    <div v-if="data[indextr].countryName">
                      <b>{{ $t('inputs.country') }} </b>{{ data[indextr].countryName }}
                    </div>
                    <div v-if="data[indextr].companyName">
                      <b>{{ $t('inputs.company') }} </b>{{ data[indextr].companyName }}
                    </div>
                  </div>
                  <div>
                    <a :href="`tel:${data[indextr].phone}`">{{ data[indextr].phone }}</a>
                  </div>
                </vs-td>

                <vs-td class="lead-overview--column lead-overview--wrap">
                  {{ data[indextr].widgetName }}
                </vs-td>

                <vs-td class="lead-overview--column lead-overview--wrap">
                  <div v-if="data[indextr].created">
                    {{ formatDate(data[indextr]) }}
                  </div>
                  <div>
                    {{ formatTime(data[indextr]) }}
                  </div>
                </vs-td>

                <vs-td class="lead-overview--visitor-status">
                  <VisitorStatus
                    :online="true"
                    :isVegaWidgetEnabled="data[indextr].visitorScore && data[indextr].isVegaWidgetEnabled"
                    :score="data[indextr].visitorScore"
                  />
                </vs-td>

                <vs-td>
                  <div>
                    <div class="flex" :style="{ direction: $vs.rtl ? 'rtl' : 'ltr' }">
                      <vs-button
                        v-if="(data[indextr].message && data[indextr].message.trim().length > 0) || data[indextr].type === 'lead-form-saved'"
                        color="success"
                        type="border"
                        size="medium"
                        icon-pack="feather"
                        icon="icon-message-square"
                        @click.stop="viewChats(data[indextr])"
                      ></vs-button>
                      <vs-button v-else color="dark" disabled type="border" size="medium" icon-pack="feather" icon="icon-message-square"></vs-button>

                      <vs-button
                        class="ml-3"
                        color="primary"
                        type="border"
                        size="medium"
                        icon-pack="feather"
                        icon="icon-info"
                        @click.stop="viewVisitorData(data[indextr])"
                      ></vs-button>

                      <vx-tooltip :text="$t('vue.delete')" position="top" class="ml-3">
                        <vs-button
                          :disabled="activeUserInfo.role !== 'admin'"
                          color="danger"
                          type="border"
                          size="medium"
                          icon-pack="feather"
                          icon="icon-trash"
                          @click="() => onDeleteClick(tr)"
                        >
                        </vs-button>
                      </vx-tooltip>
                    </div>
                  </div>
                </vs-td>
              </template>
            </vs-tr>
          </template>
        </vs-table>
      </div>
      <lead-overview-message v-if="!HAS_ACCESS && !isMobileApp"></lead-overview-message>
    </div>
  </div>
</template>

<script>
import Subscriptions from './Subscriptions.vue'
import { mapGetters } from 'vuex'

import { commonFunctions } from '@/mixins/commonFunctions'
import LeadOverviewMessage from '@/components/upgrade-messages/LeadOverviewMessage.vue'
import Visitor from '@/views/Visitor.vue'
import { mobile_check } from '@/mixins/mobile_check'

import { Device } from '@capacitor/device'

import moment from 'moment'
const dayjs = require('dayjs')
const utc = require('dayjs/plugin/utc') // dependent on utc plugin
const timezone = require('dayjs/plugin/timezone')
const relativeTime = require('dayjs/plugin/relativeTime')
const localizedFormat = require('dayjs/plugin/localizedFormat')
dayjs.extend(utc)
dayjs.extend(timezone)
dayjs.extend(relativeTime)
dayjs.extend(localizedFormat)

const commaRegExp = /,/g
const newLineRegExp = /\n/g

import ChatsViewSidebar from './ChatsViewSidebar.vue'
import LeadOverviewSidebar from './LeadOverviewSidebar.vue'

import VisitorStatus from '@/components/vega/VisitorStatus.vue'
import ConnectModal from '@/components/ConnectModal.vue'

import 'vue-multiselect/dist/vue-multiselect.min.css'
import Multiselect from 'vue-multiselect'

export default {
  mixins: [commonFunctions, mobile_check],
  components: {
    ChatsViewSidebar,
    Subscriptions,
    LeadOverviewMessage,
    LeadOverviewSidebar,
    Multiselect,
    VisitorStatus,
    Visitor,
    ConnectModal
  },
  data() {
    return {
      moment,
      selected: [],
      groups: [],
      isMobile: false,
      isMobileApp: false,
      isMounted: false,
      itemsPerPage: 5,
      showChatsSideBar: false,
      isOpenedLeadOverviewSidebar: false,
      chats: [],
      removeLead: false,
      selectedLead: null,
      id: '',
      showVisitor: false
    }
  },
  computed: {
    ...mapGetters({
      activeUserInfo: 'activeUser',
      leads: 'leads',
      company: 'company',
      userGroups: 'user_groups',
      users: 'users',
      dialogs: 'dialogs',
      visitors: 'visitors'
    }),

    userGroupList() {
      try {
        return this.userGroups.map((x) => {
          return {
            name: x.name,
            id: x.id,
            users: x.users
          }
        })
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return []
      }
    },

    distinctUsersInUserGroups() {
      try {
        if (!this.groups || this.groups.length === 0) {
          return []
        }
        const distinctUsers = new Set()

        this.groups.forEach((group) => {
          group.users.forEach((user) => {
            distinctUsers.add(user)
          })
        })

        const uniqueUsersArray = Array.from(distinctUsers)

        return uniqueUsersArray
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return []
      }
    },

    HAS_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('contact-form') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    HAS_B2B_ACCESS() {
      if (!this.company) {
        return false
      }
      let claims = this.company.claims || []
      const custom_claims = this.company.claims_custom || []
      claims = [...new Set(claims.concat(custom_claims))]

      return claims.includes('b2b-identification') && this.company.expiryDate && dayjs().isBefore(dayjs.unix(this.company.expiryDate.seconds))
    },

    userGroupsWithWidgets() {
      return this.userGroups
        .filter((x) => x.widgets && x.users && x.widgets.length > 0 && x.users.length > 0)
        .map((y) => {
          return {
            users: y.users.map((u) => u.id),
            widgets: y.widgets
          }
        })
    },

    filteredLeads() {
      return this.leads.filter((lead) => {
        const filteredWidgets = this.userGroupsWithWidgets.filter((ug) => ug.users && ug.users.length > 0 && ug.widgets && ug.widgets.includes(lead.dialogId))
        if (!filteredWidgets || filteredWidgets.length === 0) return true

        if (['superadmin', 'admin'].includes(this.activeUserInfo.role)) {
          if (!this.groups || this.groups.length === 0) {
            return true
          }
          const distinctWidgets = []
          this.groups.forEach((group) => {
            const userGroup = this.userGroups.find((ug) => ug.id === group.id)
            if (userGroup && userGroup.widgets) {
              userGroup.widgets.forEach((widget) => {
                if (!distinctWidgets.includes(widget)) {
                  distinctWidgets.push(widget)
                }
              })
            }
          })

          return distinctWidgets.includes(lead.dialogId)
        }

        const doesUserBelongToGroup = filteredWidgets.find((w) => w.users.includes(this.activeUserInfo.uid))
        return Boolean(doesUserBelongToGroup)
      })
    },

    filteredLeadsByAssignment() {
      return this.filteredLeads.filter((lead) => {
        let agents = this.distinctUsersInUserGroups
        if (this.activeUserInfo && this.activeUserInfo.role === 'agent') {
          let _name = this.activeUserInfo.displayName || `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
          if (this.activeUserInfo.displayName.indexOf('@') > -1) {
            _name = `${this.activeUserInfo.firstname} ${this.activeUserInfo.lastname}`
          }
          agents = [{ id: this.activeUserInfo.uid, name: _name }]
        }

        if (!lead.agentsToBeNotified || lead.agentsToBeNotified.length === 0 || (agents && agents.length === 0)) return true

        if (lead.agentsToBeNotified && lead.agentsToBeNotified.length > 0) {
          const allIdsExist = agents.some((obj) => lead.agentsToBeNotified.includes(obj.id))
          if (allIdsExist) {
            return true
          }
        }

        return false
      })
    },

    sortedLeads() {
      try {
        if (!this.HAS_ACCESS) {
          return []
        }
        let sortedLeads = [...this.filteredLeadsByAssignment].sort((a, b) => {
          const dateA = a.created && a.created.seconds ? dayjs.unix(a.created.seconds) : dayjs(a.created)
          const dateB = b.created && b.created.seconds ? dayjs.unix(b.created.seconds) : dayjs(b.created)

          if (dateA.isBefore(dateB)) {
            return -1
          } else if (dateA.isAfter(dateB)) {
            return 1
          } else {
            return 0
          }
        })

        sortedLeads = sortedLeads.map((item) => {
          if (item.dialogId) {
            const widget = this.dialogs.find((x) => x.id === item.dialogId)
            if (widget) {
              item.isVegaWidgetEnabled = widget.isVegaWidget && widget.isVegaWidgetEnabled
              item.widgetName = widget.name
            }
          }
          return item
        })

        return sortedLeads.reverse()
      } catch (error) {
        // eslint-disable-next-line
        console.log(error.message)
        return []
      }
    },

    currentPage() {
      if (this.isMounted) {
        return this.$refs.table.currentx
      }
      return 0
    },
    locale() {
      return this.$i18n.locale
    },
    queriedItems() {
      return this.$refs.table ? this.$refs.table.queriedResults.length : this.leads.length
    }
  },
  methods: {
    async checkIsMobileApp() {
      try {
        const { platform } = await Device.getInfo()

        if (['ios', 'android'].includes(platform)) {
          this.isMobileApp = true
        }
      } catch (error) {
        /* eslint-disable-next-line */
        console.log(error)
      }
    },

    handleSelected(tr) {
      if (!this.isMobile) {
        return
      }

      const { contacted } = tr

      setTimeout(() => {
        const currentLead = this.sortedLeads.find((item) => item.id === tr.id)

        if (!currentLead || currentLead.contacted !== contacted) {
          return
        }

        this.$serverBus.$emit('set-lead-overview-sidebar-data', tr)

        this.isOpenedLeadOverviewSidebar = true
      }, 100)
    },
    closeLeadOverviewSidebar() {
      this.selected = []
      this.removeLead = false
      this.selectedLead = null
      this.id = null
      this.isOpenedLeadOverviewSidebar = false
    },
    onCloseModal() {
      this.removeLead = false
      this.selectedLead = null
      this.id = null
    },
    exportCSV() {
      const headers = {
        index: this.$t('endscreenFeedback.number'),
        contacted: this.$t('vue.contacted'),
        name: this.$t('vue.name'),
        email: this.$t('vue.email'),
        phone: this.$t('vue.phone'),
        date: this.$t('vue.date'),
        message: this.$t('inputs.message')
      }

      const itemsFiltered = [...this.sortedLeads]

      const itemsFormatted = []

      itemsFiltered.forEach((item, index) => {
        itemsFormatted.push({
          index: index + 1,
          contacted: item.contacted,
          name: item.name ? item.name.replace(commaRegExp, '').replace(newLineRegExp, ' ') : '',
          email: item.email ? item.email.replace(commaRegExp, '').replace(newLineRegExp, ' ') : '',
          phone: item.phone ? item.phone.replace(commaRegExp, '').replace(newLineRegExp, ' ') : '',
          date: item.created ? dayjs(item.created).format('LLL').replace(commaRegExp, '').replace(newLineRegExp, ' ') : 'no data',
          message: item.message ? item.message.replace(commaRegExp, '').replace(newLineRegExp, ' ') : ''
        })
      })

      const fileTitle = this.$t('info.leadOverviewReport')
      this.exportCSVFile(headers, itemsFormatted, fileTitle)
    },
    onDeleteClick(tr) {
      this.removeLead = true
      this.selectedLead = tr
      this.id = tr.id
    },
    async deleteLead() {
      await this.$vs.loading()
      await this.$db.collection('visitor-contacts').doc(this.id).delete()
      this.removeLead = false
      setTimeout(() => {
        this.$vs.loading.close()
      }, 300)
    },
    formatDate(contact) {
      if (!contact.created) {
        return 'no data'
      } else {
        return dayjs(contact.created).format('LL')
      }
    },
    formatTime(contact) {
      if (!contact.created) {
        return 'no data'
      } else {
        return dayjs(contact.created).format('LT')
      }
    },
    async viewChats(data) {
      const chats = []

      if (data && data.leadMessages && data.leadMessages.length) {
        data.leadMessages.forEach((message) => {
          const modifiedMessage = {
            name: data.name,
            message: message.message,
            sender: message.sender,
            agentName: message.agentName,
            time: message.time && message.time.seconds ? dayjs.unix(message.time.seconds) : message.time
          }

          if (message.leadForm) {
            let messageStr = ''

            if (message.leadForm.name) {
              messageStr += `Name: ${message.leadForm.name} <br>`
            }
            if (message.leadForm.email) {
              messageStr += `Email: ${message.leadForm.email} <br>`
            }
            if (message.leadForm.phone) {
              messageStr += `Phone: ${message.leadForm.phone} <br>`
            }
            if (message.leadForm.countryName) {
              messageStr += `Country: ${message.leadForm.countryName} <br>`
            }
            if (message.leadForm.companyName) {
              messageStr += `Company: ${message.leadForm.companyName} <br>`
            }

            modifiedMessage.message = messageStr
          }

          chats.push(modifiedMessage)
        })
      } else {
        const message = {
          name: data.name,
          chatTime: dayjs.utc(data.created).diff('2021-01-01'),
          message: data.message,
          sender: 'visitor',
          time: data.created
        }
        chats.push(message)
      }

      this.chats = chats
      this.toggleChatsDataSidebar(true)
    },
    toggleChatsDataSidebar(val = false) {
      this.showChatsSideBar = val
    },
    async saveContacted(data) {
      const ref = this.$db.collection('visitor-contacts').doc(data.id)
      const info = {
        contacted: !data.contacted
      }
      await ref.set(info, { merge: true })
    },

    async setLastReadPage() {
      await this.$db
        .collection('users')
        .doc(this.activeUserInfo.uid)
        .set(
          {
            lastReadPages: {
              leadOverview: new Date()
            }
          },
          { merge: true }
        )
    },

    async viewVisitorData(data) {
      if (!this.HAS_B2B_ACCESS && data.b2b) {
        this.$vs.notify({
          time: 8800,
          title: this.$i18n.t('vue.error'),
          text: this.$i18n.t('vue.accessDenied'),
          iconPack: 'feather',
          icon: 'icon-alert-circle',
          color: 'danger'
        })

        return
      }

      if (this.visitors) {
        let visitor = this.visitors.find((item) => data.visitorId === item.id)
        if (!visitor) {
          const visitorRef = await this.$db.collection('visitors').doc(data.visitorId).get()
          visitor = visitorRef.data()
        } else {
          visitor.isVegaWidgetEnabled = data.isVegaWidgetEnabled
          visitor.widgetName = data.name
        }

        if (visitor) {
          this.showVisitor = true
          this.$serverBus.$emit('get-company-info', visitor)
        }
      }
    }
  },

  created() {
    this.isMobile = this.mobileCheck()

    this.checkIsMobileApp()
  },

  async mounted() {
    await this.setLastReadPage()

    this.$serverBus.$on('close-company-info', () => {
      this.showVisitor = false
    })

    this.isMounted = true
  },

  async beforeDestroy() {
    await this.setLastReadPage()
    this.$serverBus.$off('close-company-info')
  }
}
</script>

<style lang="scss">
.lead-overview {
  &__headers {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    position: relative;
  }

  &--wrap {
    text-align: left;
    word-break: break-word;
    font-family: 'Lato';
    word-wrap: break-word;
    max-width: 100px;
  }

  &--visitor-status {
    span {
      display: inline-block;
    }
  }

  &--column {
    padding: 10px 15px !important;
  }

  &__header {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    flex-direction: row;
    &-multiselect {
      min-height: 300px;
    }

    width: 100%;

    .multiselect__tags {
      border: none;
    }

    .multiselect--active {
      z-index: 999999999;
    }

    .multiselect__option--highlight {
      background: #3034f7;
    }

    .multiselect__option--selected {
      background: #38a7f9;
      color: white;
    }
  }
}

#data-list-thumb-view {
  .leadoverview-container {
    min-height: 300px;
    position: relative;

    .vs-table--search {
      padding-bottom: 0;
    }
  }
  .vs-con-table {
    .product-name {
      max-width: 23rem;
    }

    .vs-table--header {
      display: flex;
      flex-wrap: wrap-reverse;
      margin-left: 1.5rem;
      margin-right: 1.5rem;
      > span {
        display: flex;
        flex-grow: 1;
      }

      .vs-table--search {
        padding-top: 0;

        .vs-table--search-input {
          padding: 0.9rem 2.5rem;
          font-size: 1rem;

          & + i {
            left: 1rem;
          }

          &:focus + i {
            left: 1rem;
          }
        }
      }
    }

    .vs-table {
      border-collapse: separate;
      border-spacing: 0 1.3rem;
      padding: 0 1rem;

      tr {
        box-shadow: 0 4px 20px 0 rgba(0, 0, 0, 0.05);
        td {
          padding: 10px;
          &:first-child {
            border-top-left-radius: 0.5rem;
            border-bottom-left-radius: 0.5rem;
          }
          &:last-child {
            border-top-right-radius: 0.5rem;
            border-bottom-right-radius: 0.5rem;
          }
          &.img-container {
            // width: 1rem;
            // background: #fff;

            span {
              display: flex;
              justify-content: flex-start;
            }

            .product-img {
              height: 110px;
            }
          }
        }
        td.td-check {
          padding: 20px !important;
        }
      }
    }

    .vs-table--thead {
      th {
        padding-top: 0;
        padding-bottom: 0;

        .vs-table-text {
          text-transform: capitalize;
          font-weight: 600;
          box-shadow: none !important;
          font-size: 14px !important;
        }
      }
      th.td-check {
        padding: 0 15px !important;
      }
      tr {
        background: none;
        box-shadow: none;
      }
    }

    .vs-table--pagination {
      justify-content: center;
    }
  }
}

.data-list-container--mobile {
  .vs-con-table {
    .vs-table--header {
      margin-left: 0px !important;
      margin-right: 0px !important;
    }

    .vs-table tr td {
      padding: 20px !important;
    }

    tr {
      td:first-child {
        padding-right: 10px !important;
        width: 90px !important;
      }

      td:last-child {
        padding-left: 15px !important;
        padding-right: 35px !important;
      }
    }

    .tr-values {
      background-image: url('../../assets/images/elements/arrow-blue.png');
      background-position: calc(100% - 20px) 50%;
      background-size: auto;
      background-repeat: no-repeat;
    }
  }

  .vs-table--thead {
    th:first-child {
      padding-left: 0px !important;
    }
  }

  .vs-table--tbody-table {
    padding: 0 !important;
    min-width: 100% !important;
  }

  .vs-table--pagination {
    padding: 0px !important;

    .item-pagination {
      width: 25px !important;
      height: 25px !important;
    }
  }

  .header-table {
    flex-wrap: wrap !important;
  }

  .vs-switch {
    width: 55px !important;
  }

  .text-switch {
    .vs-icon {
      display: none !important;
    }
  }

  @media only screen and (max-width: 600px) {
    .data-list-subscriptions-wrapper {
      width: 100%;
    }

    .btn-add-new {
      margin-bottom: 20px !important;
    }

    .lead-overview__header--multiselect {
      margin-top: -15px;
      margin-bottom: 5px;
    }

    .con-input-search {
      padding-bottom: 0px !important;
      width: 100% !important;
      max-width: 100% !important;
    }

    .input-search {
      width: 100% !important;
    }
  }
}

.visitor-modal {
  .modal-container {
    padding: 0 !important;
  }
}
</style>
